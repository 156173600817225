<template>
  <div class="note-container">
    <el-row>
      <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="2"></el-col>
      <el-col :xs="22" :sm="22" :md="22" :lg="20" :xl="20">
        <div class="grid-content">
          <div class="left">
          </div>
          <div class="right">
            <el-carousel
              trigger="click"
              height="50px"
              autoplay
              arrow="never"
              direction="vertical"
              indicator-position="none">
              <el-carousel-item v-for="item in noteList" :key="item.id">
                <div class="note-info">{{ item.noticeTitle }}</div>
                <el-button type="text" @click="handleLink(item.id)">查看详情<i class="el-icon-arrow-right"></i></el-button>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </el-col>
      <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="2"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'LatestNote',
  props: {
    noteList: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleLink(id) {
      this.$router.push({
        path: '/note_detail',
        query: { id: id }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.note-container{
  width: 100%;
  min-width: 768px;
  height: 90px;
  margin: 20px 0;
  margin-top: -25.2vh;
  .el-row{
    height: 90px;
    display: flex;
    align-items: center;
    .grid-content{
      display: flex;
      justify-content: space-between;
      height: 50px;
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      align-items: center;
      background: rgba(255,255,255,0.20);
      border: 1px solid rgba(204,224,248,1);
      box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.03);
      border-radius: 6px;
      .left{
        width: 108px;
        height: 32px;
        background-image: url("icon/icon-wenzi.svg");
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        &::after{
          content: '';
          width: 1px;
          height: 22px;
          background-color: #D8D8D8;
          position: absolute;
          right: -20px;
          top: 4px;
        }
      }
      .right{
        min-width: 500px;
        height: 50px;
        box-sizing: border-box;
        padding-left: 40px;
        flex-grow: 1;
        font-size: 16px;
        line-height: 22px;
        color: #262626;
        .el-carousel{
          .el-carousel__container{
            .el-carousel__item{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .note-info{
                flex-grow: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .el-button{
                color: #0042FF;
                font-size: 16px;
                font-weight: 200;
                &:hover{
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* 修改公告的滚动速度（默认是0.4s） */
.el-carousel__item.is-animating {
  transition: transform 0.8s ease-in-out;
}
</style>
